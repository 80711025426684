<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="关键词">
          <el-input v-model="search_wechat_keywords" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="search_wechat_contents" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="search_remark" placeholder=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add"
        >添加</el-button
      >
    </div>
    <!-- 表单 -->
    <el-table
      v-loading="loading"
      :data="blackList"
      border
      style="width: 100%"
    >
      <el-table-column
        fixed
        label="编号"
        prop="id"
        width="50"
      ></el-table-column>
<el-table-column label="类型" width="60">
  <template slot-scope="item">
    {{item.row.type | type}}
  </template>
</el-table-column>
<el-table-column label="关键词" prop="wechat_keywords" width="100"></el-table-column>
<el-table-column label="内容" prop="wechat_contents" width="500"></el-table-column>
<el-table-column label="关系" prop="">
    <template slot-scope="item">
    {{item.row.relation | relation}}
  </template>
</el-table-column>
<el-table-column label="状态" prop="">
    <template slot-scope="item">
    {{item.row.is_valid | valid}}
  </template>
</el-table-column>
<el-table-column label="描述" prop="remark"></el-table-column>
<el-table-column label="创建时间">
    <template slot-scope="item">
    {{item.row.create_time | dateTime}}
  </template>
</el-table-column>
<el-table-column label="更新时间">
    <template slot-scope="item">
    {{item.row.update_time | dateTime}}
  </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
          <el-button
            type="primary"
            size="mini"
            @click="getWechat(item.row.id)"
            >修改</el-button
          >
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal="false">
    <el-form>
        <el-form-item label="类型:">
            <el-radio-group v-model="type">
                <el-radio label="1">文本</el-radio>
                <el-radio label="2">图片</el-radio>
                <el-radio label="3">链接</el-radio>
                <el-radio label="4">超文本</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="关键词：">
            <el-input v-model="wechat_keywords" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="内容：">
            <input type="file" name="" id="up" @change="weChatMediaId">
            <div v-if="type==2">
                <el-row>
                    <el-col :span="12">
                        <el-input v-model="wechat_contents" placeholder=""></el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" @click="upload">上传图片</el-button>
                    </el-col>
                </el-row>
            </div>
            <el-input v-else v-model="wechat_contents" type="textarea" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="关系:">
            <el-radio-group v-model="relation">
                <el-radio label="1">等于</el-radio>
                <el-radio label="2">包含</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="状态:">
            <el-radio-group v-model="is_valid">
                <el-radio label="1">启用</el-radio>
                <el-radio label="2">停用</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：">
            <el-input v-model="remark" placeholder=""></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem("common"));
    import * as api from "@/config/api";
    export default {
        name: "xxgwtj",
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,


                dialog: false,
                blackList: [],

                search_wechat_keywords: '',
                search_wechat_contents: '',
                search_remark: '',

                platform: '2',
                id: "",
                wechat_keywords: '',
                relation: '',
                wechat_contents: '',
                type: '',
                is_valid: '',
                remark: '',
            };
        },
        filters: {
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            },
            type(val) {
                switch (Number(val)) {
                    case 1:
                        return '文本'
                        break;
                    case 2:
                        return '图片'
                        break;
                    case 3:
                        return 'url'
                        break;
                    case 4:
                        return '超文本'
                        break;
                    default:
                        break;
                }
            },
            valid(val) {
                switch (Number(val)) {
                    case 1:
                        return '启用'
                        break;
                    case 2:
                        return '停用'
                        break;
                    default:
                        break;
                }
            },
            relation(val) {
                switch (Number(val)) {
                    case 1:
                        return '等于'
                        break;
                    case 2:
                        return '包含'
                        break;

                    default:
                        break;
                }
            }
        },
        watch: {},
        mounted() {
            this.getWechatList();
        },
        methods: {
            getTime(time) {
                let Time = new Date(time * 1000);
                return `${Time.getFullYear()}-${Time.getMonth() + 1 < 10 ? "0" + (Time.getMonth() + 1) : Time.getMonth() + 1}-${Time.getDate() < 10 ? "0" + Time.getDate() : Time.getDate()} ${Time.getHours() < 10 ? "0" + Time.getHours() : Time.getHours()}:${Time.getMinutes() < 10 ? "0" + Time.getMinutes() : Time.getMinutes()}:${Time.getSeconds() < 10 ? "0" + Time.getSeconds() : Time.getSeconds()}`
            },
            // 获取列表
            getWechatList(page, size) {
                api.getWechatList({
                        page: page ? page : this.page,
                        pagesize: size ? size : this.pagesize,
                        wechat_keywords: this.search_wechat_keywords,
                        wechat_contents: this.search_wechat_contents,
                        remark: this.search_remark,
                        platform: this.platform
                    },
                    (res) => {
                        this.blackList = res.data.list;
                        this.total = Number(res.data.count);
                    }
                );
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val;
                this.getWechatList();
            },
            currentChange(val) {
                this.page = val;
                this.getWechatList();
            },
            //搜索
            seach(page) {
                this.page = page;
                this.getWechatList(page);
            },
            reset(page) {
                this.search_id = "";
                this.page = page;
                this.getWechatList(page);
            },

            // 添加选品
            add() {
                this.id = ""
                this.id = ""
                this.wechat_keywords = ''
                this.relation = ''
                this.wechat_contents = ''
                this.type = ''
                this.is_valid = ''
                this.remark = ''
                this.dialog = true;
            },
            getWechat(id) {
                api.getWechat({
                    id
                }, res => {
                    this.platform = res.data.platform,
                        this.id = res.data.id,
                        this.wechat_keywords = res.data.wechat_keywords,
                        this.relation = res.data.relation,
                        this.wechat_contents = res.data.wechat_contents,
                        this.type = res.data.type,
                        this.is_valid = res.data.is_valid,
                        this.remark = res.data.remark,
                        this.dialog = true;
                })
            },
            // 修改、添加频道分类
            alter() {
                if (this.id != '') {
                    api.updateWechat({
                            id: this.id,
                            platform: this.platform,
                            wechat_keywords: this.wechat_keywords,
                            relation: this.relation,
                            wechat_contents: this.wechat_contents,
                            type: this.type,
                            is_valid: this.is_valid,
                            remark: this.remark,
                        },
                        (res) => {
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                            this.dialog = false;
                            this.getWechatList();
                        }
                    );
                    return
                }
                api.addWechat({
                        platform: this.platform,
                        wechat_keywords: this.wechat_keywords,
                        relation: this.relation,
                        wechat_contents: this.wechat_contents,
                        type: this.type,
                        is_valid: this.is_valid,
                        remark: this.remark,
                    },
                    (res) => {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.dialog = false;
                        this.getWechatList();
                    }
                );
            },
            upload() {
                document.getElementById('up').click()
            },
            weChatMediaId() {
                // this.loading = true;
                let file = document.getElementById('up').files;
                let formData = new FormData();
                formData.append('platform', this.platform)
                formData.append("file", file[0]);
                api.weChatMediaId(formData, res => {
                    console.log(res.data.url)
                    this.wechat_contents = res.data.media_id;

                })
            }
        },
    };
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
    
    input[type="file"] {
        display: none;
    }
</style>